<template>
  <section class="app-container">
    <HeroHeaderComponent />
    <AboutMeComponent ref="aboutMeComponent" :visible="aboutMeVisible"/>
    <ResumeComponent ref="resumeComponent" :visible="resumeVisible"/>
    <GetInTouchComponent ref="getInTouchComponent" :visible="getInTouchVisible" />
    <FooterComponent />
  </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import HeroHeaderComponent from './components/HeroHeaderComponent.vue'
import AboutMeComponent from './components/AboutMeComponent.vue'
import ResumeComponent from './components/ResumeComponent.vue'
import GetInTouchComponent from './components/GetInTouchComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

export default {
  name: 'App',
  components: {
    HeroHeaderComponent,
    AboutMeComponent,
    ResumeComponent,
    GetInTouchComponent,
    FooterComponent
  },
  setup: () => {
    const aboutMeVisible = ref();
    const aboutMeComponent = ref();
    const resumeVisible = ref();
    const resumeComponent = ref();
    const getInTouchVisible = ref();
    const getInTouchComponent = ref();

    onMounted(() => {
      setupIntersectionHandlers();
    });

    const setupIntersectionHandlers = () => {
      createInterectionObserverForSection(aboutMeComponent.value.$el, aboutMeVisible);
      createInterectionObserverForSection(resumeComponent.value.$el, resumeVisible);
      createInterectionObserverForSection(getInTouchComponent.value.$el, getInTouchVisible);
    }

    const createInterectionObserverForSection = (section, sectionVisible) => {
      const options = {
        rootMargin: '0px',
        threshold: 0.25
      }

      const callback = (entries) => {
        if (entries[0].isIntersecting) {
          sectionVisible.value = true;
        }
      }

      const observer = new IntersectionObserver(callback, options);
      observer.observe(section);
    }

    return {
      aboutMeComponent,
      resumeComponent,
      getInTouchComponent,
      aboutMeVisible,
      resumeVisible,
      getInTouchVisible
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/style/main.scss';

  .app-container {
    overflow: hidden;
  }
</style>
