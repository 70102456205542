<template>
  <div id="howIWork" class="about-me">
    <section>
      <h1 :class="[visible ? 'animate-in' : '']">HOW I WORK <em>.</em></h1>
      <img :class="[visible ? 'animate-in' : '']" src="@/assets/images/nan-avatar.jpg" alt="Nan"/>
      <img :class="[visible ? 'animate-in' : '', 'overlay-1']" src="@/assets/images/nan-avatar.jpg" alt="Nan"/>
      <img :class="[visible ? 'animate-in' : '', 'overlay-2']" src="@/assets/images/nan-avatar.jpg" alt="Nan"/>
      <img :class="[visible ? 'animate-in' : '', 'overlay-3']" src="@/assets/images/nan-avatar.jpg" alt="Nan"/>
    </section>
    <section :class="['text', visible ? 'animate-in' : '']">
      <p>
        As a Freelance Front-end Developer, my services focus on delivering highly performant and pragmatically delivered Web-based Front-end solutions, with a strong emphasis on UX.
      </p>
      <p>
        I’m not here to convince you a single framework is the best, since I’m a firm believer of using the right tool to fit the right job. Through the years I’ve built up a solid experience of 8+ years building (web)apps and API’s with a wide array of technologies in a professional environment, while working in both Agency and product teams at companies like Relayter, Label A and Icemobile. 
      </p>
      <p>
        Personally I feel like I thrive best working together in multi disciplinary agile team, where we can get the most out of our shared project together.
      </p>
      <p><b>
          In all the work that I do, I value sincerity, pragmatism 
  and a no nonsense approach.
      </b></p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AboutMeComponent',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss" >
  @import '@/assets/style/main.scss';

  .about-me {
    display: flex;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 23%);

    section {
      position: relative;
      width: 500px;
      padding-top: 80px;

      h1 {
        position: absolute;
        opacity: 0;
        top: 48px;
        left: 48px;
        z-index: 20;
      }

      h1.animate-in {
        animation: slideLeftIn 1s cubic-bezier(0.55, 0.09, 0.44, 1.21) 0s 1 both;
      }

      img {
        width: 100%;
        clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
        position: absolute;
        z-index: 2;
        opacity: 0;
      }

      img.animate-in {
        animation: fadeIn 1s cubic-bezier(0.55, 0.09, 0.44, 1.21) 0s 1 both;
      }

      img.overlay-1 {
        position: absolute;
        left: 0;
        z-index: 0;
      }

      img.overlay-1.animate-in {
        animation: twistIn1 1s cubic-bezier(0.55, 0.09, 0.44, 1.21) 0.5s 1 both;
      }

      img.overlay-2 {
        position: absolute;
        left: 0;
        z-index: 0;
      }

      img.overlay-2.animate-in {
        animation: twistIn2 1s cubic-bezier(0.55, 0.09, 0.44, 1.21) 0.5s 1 both;
      }

      img.overlay-3 {
        position: absolute;
        left: 0;
        z-index: 0;
      }

      img.overlay-3.animate-in {
        animation: twistIn3 1s cubic-bezier(0.55, 0.09, 0.44, 1.21) 0.5s 1 both;
      }
    }

    section.text {
      flex: 1;
      opacity: 0;
    }

    section.text.animate-in {
      animation: slideRightIn 1s cubic-bezier(0.55, 0.09, 0.44, 1.21) 0s 1 both;
    }
  }

  @media (min-width: 1920px) {
    .about-me {
      section.text {
        padding-right: 250px;
        padding-left: 100px;
      }
    }
  }

@media (max-width: $breakpoint-tablet) {
  .about-me  {
    flex-direction: column;
    section {
      width: 100%;

      img,
      img.overlay-1,
      img.overlay-2,
      img.overlay-3 {
        max-width: 80%;
        left: 10%;
      }

      img {
        position: relative;
      }

      img.overlay-1,
      img.overlay-2,
      img.overlay-3 {
        position: absolute;
      }
    }

    section.text.animate-in {
      animation: slideTopIn 1s cubic-bezier(0.55, 0.09, 0.44, 1.21) 0s 1 both;
    }
  }
}

  @keyframes slideRightIn {
    0% {
      opacity:0;
      transform: translateX(200px) translateZ(0);
    }

    100% {
      opacity:1;
      transform: translateX(0) translateZ(0);
    }
  }

  @keyframes slideLeftIn {
    0% {
      opacity:0;
      transform: translateX(-200px) translateZ(0);
    }

    100% {
      opacity:1;
      transform: translateX(0) translateZ(0);
    }
  }

  @keyframes twistIn1 {
    0% {
      opacity:0;
    }

    100% {
      opacity: 0.4;
      transform: rotate(-8deg) translateZ(0);
    }
  }

  @keyframes twistIn2 {
    0% {
      opacity:0;
    }

    100% {
      opacity: 0.4;
      transform: rotate(8deg) translateZ(0);
    }
  }

  @keyframes twistIn3 {
    0% {
      opacity:0;
    }

    100% {
      opacity: 0.4;
      transform: rotate(-16deg) translateZ(0);
    }
  }

</style>
