<template>
  <div id="resume" class="resume">
    <span :class="[visible ? 'animate-in' : '', 'first-background']"/>
    <span :class="[visible ? 'animate-in' : '', 'second-background']"/>
    <span :class="[visible ? 'animate-in' : '', 'third-background']"/>
    <span :class="[visible ? 'animate-in' : '', 'fourth-background']"/>
    <h2 :class="[visible ? 'animate-in' : '']">Interested in some of my latest <em>experience</em> at former clients?</h2>
    <a :class="[visible ? 'animate-in' : '']" href="https://www.linkedin.com/in/nan-damhuis-74987166/" target="_blank" class="button primary">Connect on Linkedin<em>.</em></a>
  </div>
</template>

<script>
export default {
  name: 'ResumeComponent',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/style/main.scss';

  .resume {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 210px 215px;
    margin-top: 280px;
    overflow: hidden;
  
    h2 {
      padding-bottom: 48px;
      text-align: center;
      letter-spacing: 0.3px;
      color: $secondary-text-color;
      z-index: 20;
      opacity: 0;
    }

    a {
      opacity: 0;
      z-index: 50;
    }

    a.animate-in, 
    h2.animate-in {
      animation: slideTopIn 1s cubic-bezier(0.55, 0.09, 0.44, 1.21) 0.5s 1 both;
    }

    span {
      position: absolute;
      background: rgba(121, 194, 190, 0.1);
      height: 400px;
      width: 120vw;
      opacity: 0;
      z-index: -1;
    }

    span.first-background.animate-in {
      animation: twistIn1 1s cubic-bezier(0.55, 0.09, 0.44, 1.21) 0s 1 both;
    }

    span.second-background.animate-in {
      animation: twistIn2 1s cubic-bezier(0.55, 0.09, 0.44, 1.21) 0s 1 both;
    }

    span.third-background.animate-in {
      animation: twistIn3 1s cubic-bezier(0.55, 0.09, 0.44, 1.21) 0s 1 both;
    }

    img.fourth-background.animate-in {
      animation: twistIn4 1s cubic-bezier(0.55, 0.09, 0.44, 1.21) 0s 1 both;
    }
  }

  @keyframes twistIn1 {
    0% {
      opacity:0;
    }

    100% {
      opacity: 1;
      transform: rotate(-5deg) scale(0.9) translateZ(0);
    }
  }

  @keyframes twistIn2 {
    0% {
      opacity:0;
    }

    100% {
      opacity: 1;
      transform: rotate(4deg) scale(1.1) translateZ(0);
    }
  }

  @keyframes twistIn3 {
    0% {
      opacity:0;
    }

    100% {
      opacity: 1;
      transform: rotate(-2.5deg) scale(1.05) translateZ(0);
    }
  }

  @keyframes twistIn4 {
    0% {
      opacity:0;
    }

    100% {
      opacity: 0.4;
      transform: rotate(-8deg) translateZ(0);
    }
  }

@media (min-width: 1800px) {
  .resume {
    margin: 400px 0 200px 0;
  }
}

@media (max-width: $breakpoint-tablet) {
  .resume {
    margin-top: 0;
    padding: 180px 24px;

    h2 {
      font-size: 2em;
      padding: 0 0 48px;
    }

    a {
      font-size: 1em;
    }
  }
}
</style>
