<template>
  <div class="footer">
    <img class="background" src="@/assets/images/footer-background.svg" alt="Footer background"/>
    <section>
      <a href="#heroHeader"><img src="@/assets/images/logo-large.svg" alt="Margin Zero logo"/></a>
      <label>2022 <em>*</em> BTW ID: NL003638759B91</label>
    </section>
  </div>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped lang="scss" >
  @import '@/assets/style/main.scss';
  
  .footer {
    position: relative;
    width: 100%;
    height: 330px;
    background: white;

    section {
      position: absolute;
      width: 320px;
      left: 64px;
      bottom: 64px;
      display: flex;
      flex-direction: column;

      a {
        img {
          width: 320px;
        }
      }
    }

    section label {
      font-size: 1em;
      padding-top: 8px;
      text-align: right;
      color: $primary-text-color;
    }

    .background {
      position: absolute;
      width: 100%;
      bottom: -11vw;
      pointer-events: none;
    }
  }

  @media (max-width: $breakpoint-QHD) {
    .footer {
      section {
        width: 320px;
        a {
          img {
            width: 320px;
          }
        }
      }

      .background {
        bottom: 0;
        bottom: -5vw;
      }
    }
  }


  @media (max-width: $breakpoint-tablet) {
    .footer {
      .background {
        bottom: 0;
        width: 170%;
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .footer {

      height: 240px;

      section {
        width: 70%;
        left: 10%;
        bottom: 16px;
        a {
          img {
            width: 100%;
          }
        }
      }
      .background {
        width: 210%;
      }
    }
  }
</style>
