<template>
  <div class="logo-container">
    <img class="logo-margin" src="@/assets/images/logo-large-margin.svg" alt="Margin"/>
    <img class="logo-center" src="@/assets/images/logo-large-center.svg" alt="Logo"/>
    <img class="logo-zero" src="@/assets/images/logo-large-zero.svg" alt="Zero"/>
  </div>
</template>

<script>
export default {
  name: 'LogoAnimationComponent'
}
</script>

<style scoped lang="scss" >
  @import '@/assets/style/main.scss';

  .logo-container {
    display: flex;
    flex-direction: row;
  }

  .logo-margin {
    padding-left: 8px;
    width: 204px;
    animation: slideLeftInOut 2s cubic-bezier(0.55, 0.09, 0.68, 0.53) 0s 1 both;
  }

  .logo-center {
    padding-left: 10px;
    padding-right: 4px;
    width: 44px;
    animation: slideFromBottomToTop 2s cubic-bezier(0.55, 0.09, 0.68, 0.53) 0s 1 both;
  }

  .logo-zero {
    padding-left: 8px;
    width: 134px;
    animation: slideRightInOut 2s cubic-bezier(0.55, 0.09, 0.68, 0.53) 0s 1 both;
  }

  @media (max-width: $breakpoint-tablet) {
    .logo-margin {
      width: 153px;
    }

    .logo-center {
      width: 32px;
    }

    .logo-zero {
      width: 100px;
    }
  }

  @keyframes slideFromBottomToTop {
    0% {
      opacity:0;
      // transform: translateY(20px) translateZ(0);
    }
    40% {
      opacity:1;
      // transform: translateY(0px) rotate(0deg) translateZ(0);
    }
    60% {
      opacity:1;
      // transform: translateY(0px) rotate(0deg) translateZ(0);
    }
    100% {
      opacity:0;
      // transform: translateY(-20px) translateZ(0);
    }
  }

  @keyframes slideLeftInOut {
    0% {
      opacity:0;
      transform: translateX(-20px) translateZ(0);
    }
    40% {
      opacity:1;
      transform: translateX(0px) translateZ(0);
    }
    60% {
      opacity:1;
      transform: translateX(0px) translateZ(0);
    }
    100% {
      opacity:0;
      transform: translateX(-20px) translateZ(0);
    }
  }

  @keyframes slideRightInOut {
    0% {
      opacity:0;
      transform: translateX(20px) translateZ(0);
    }
    40% {
      opacity:1;
      transform: translateX(0px) translateZ(0);
    }
    60% {
      opacity:1;
      transform: translateX(0px) translateZ(0);
    }
    100% {
      opacity:0;
      transform: translateX(20px) translateZ(0);
    }
  }
</style>
