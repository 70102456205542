<template>
  <div id="getInTouch" class="get-in-touch" :class="[visible ? 'animate-in' : '']">
    <h3>Interested on collaborating on a project? </h3>
    <AvailabilityComponent isBodyVersion/>
    <h3>Get in touch at <em><a href="mailto: hello@marginzero.nl" target="_blank">hello@marginzero.nl</a></em></h3>
    <img class="icon" src="@/assets/images/footnote-icon.svg" alt="Icon"/>
    <section>
      <a href="https://www.linkedin.com/in/nan-damhuis-74987166/" target="_blank"><em>l</em>inkedin</a>
      <a href="https://github.com/nanzero" target="_blank"><em>g</em>ithub</a>
    </section>
  </div>
</template>

<script>
import AvailabilityComponent from './AvailabilityComponent.vue'
export default {
  components: { AvailabilityComponent },
  name: 'GetInTouchComponent',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss" >
  @import '@/assets/style/main.scss';

  .get-in-touch {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 128px;
    background: white;
    opacity: 0;

    .icon {
      padding: 24px 0 48px 0;
    }

    h3 {
      text-align: center;
      a {
        margin: 0;
        font-family: $primary-font;
        font-size: 1em;
        letter-spacing: 0.3px;
        color: $tertiary-text-color;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  
    section {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        padding: 0 36px;
        font-family: $secondary-font;
        font-size: 1.75em;
        letter-spacing: 0.3px;
        color: $primary-text-color;
        z-index: 20;
      }

      a:hover {
        color: $tertiary-text-color;
      }

      a:hover em {
        color: $primary-text-color;
      }
    }
  }

  .get-in-touch.animate-in {
    animation: slideTopIn 1s cubic-bezier(0.55, 0.09, 0.44, 1.21) 0s 1 both;
  }

  @media (max-width: $breakpoint-tablet) {
    .get-in-touch {
      padding-bottom: 48px;

      .icon {
        padding: 48px 0 48px 0;
      }

      h3 {
        padding: 16px 24px;
        font-size: 1.5em;
      }

      section {
        a {
          padding: 16px 24px;
          font-size: 1.5em;
        }
      }
    }
  }
</style>
