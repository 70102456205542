<template>
  <div id="heroHeader" ref="heroHeader" class="hero-header">
    <NavigationComponent class="content-load"/>
    <section class="content-load-slow">
      <h1>Hi! My name is <em>Nan Damhuis.</em></h1>
      <h1>I am a <em>Freelance Front-end Developer</em> from Amsterdam</h1>
      <a class="button primary content-load-slower" @click="scrollToElement">Let's get in touch<em>.</em></a>
    </section>
    <LogoAnimationComponent class="loading-logo"/>
    <VectorAnimationComponent :mousePosition="mousePosition"/>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import NavigationComponent from './NavigationComponent.vue'
import VectorAnimationComponent from './VectorAnimationComponent.vue';
import LogoAnimationComponent from './LogoAnimationComponent.vue';
import ScrollHelper from '../helpers/scroll-helper';

export default {
  components: { 
    NavigationComponent,
    VectorAnimationComponent,
    LogoAnimationComponent
  },
  name: 'HeroHeaderComponent',
  setup: () => {
    const heroHeader = ref();
    const mousePosition = ref();

    onMounted(() => {
      heroHeader.value.addEventListener('mousemove', event => {
        mousePosition.value = {x: event.clientX, y: event.clientY};
      }, {passive: true});
    })

    const scrollToElement = () => {
      ScrollHelper.scrollToElementById('getInTouch');
    };

    return {
      heroHeader,
      mousePosition,
      scrollToElement
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/style/main.scss';

  .hero-header {
    position: relative;
    height: 110vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 20%);

    .content-load {
      opacity: 0;
      animation: fadeIn 1s cubic-bezier(0.55, 0.09, 0.68, 0.53) 1s 1 both;
    }

    .content-load-slow {
      opacity: 0;
      animation: fadeIn 1s cubic-bezier(0.55, 0.09, 0.68, 0.53) 1.5s 1 both;
    }

    .content-load-slower {
      opacity: 0;
      animation: fadeIn 1s cubic-bezier(0.55, 0.09, 0.68, 0.53) 1.75s 1 both;
    }

    .loading-logo {
      position: absolute;
      width: 400px;
      transform: translateY(-25px);
    }

    section {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      justify-content: center;

      padding: 0 10%;
      transform: translateY(-10vh);

      h1 {
        width: 100%;
        text-align: center;
      }

      a {
        margin-top: 80px;
        z-index: 20;
        overflow: hidden;
        position: relative;
      } 
    }
  }

  @media (max-width: $breakpoint-QHD) {
    section {
      padding: 24px;
    }
  }

  @media (max-width: 1024px) {
    section {
      h1 {
        padding-bottom: 16px;
      }
    }
  }

  @media (max-width: $breakpoint-tablet) {
    .hero-header {

      section {
        h1 {
          font-size: 2em;
          padding-bottom: 16px;
        }

        a {
          margin-top: 40px;
          font-size: 1em;
        }
      }
      .loading-logo {
        width: 325px;
      }
    }
  }
</style>
