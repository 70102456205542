<template>
  <nav>
    <section class="left">
      <img src="@/assets/images/logo-small.svg" alt="Margin Zero logo small"/>
      <AvailabilityComponent />
    </section>
    <section class="right">
      <a @click="scrollToElement('howIWork')">HOW I WORK<em>.</em></a>
      <a @click="scrollToElement('resume')">RESUME<em>.</em></a>
      <a @click="scrollToElement('getInTouch')">GET IN TOUCH<em>.</em></a>
    </section>
  </nav>
</template>

<script>
import AvailabilityComponent from './AvailabilityComponent.vue'
import ScrollHelper from '../helpers/scroll-helper';
export default {
  components: { 
    AvailabilityComponent 
  },
  name: 'NavigationComponent',
  setup: () => {
    const scrollToElement = id => {
      ScrollHelper.scrollToElementById(id);
    };

    return {
      scrollToElement
    }
  }
}
</script>

<style scoped lang="scss" >
  @import '@/assets/style/main.scss';

  nav {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 36px 36px 24px 24px;
    z-index: 20;

    section {
      display: flex;
      align-items: center;

      a {
        padding-left: 24px;
      }

      a:hover {
        color: $tertiary-text-color;
      }

      a:hover em {
        color: $primary-text-color;
      }
    }

    img {
      margin-right: 36px;
      width: 48px;
    }
  }

  em {
    font-size: 1.5em;
  }

  @media (max-width: 1024px) {
    nav {
      .right {
        display: none;
      }
    }
  }
</style>
