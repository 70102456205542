<template>
  <div>
   <h3 :class="{'body-version': isBodyVersion}"></h3>
  </div>
</template>

<script>
export default {
  name: 'AvailabilityComponent',
  props: {
    isBodyVersion: {
      type: Boolean,
      value: false
    }
  }
}
</script>

<style scoped lang="scss" >
  @import '@/assets/style/main.scss';

  h3 {
    font-family: $secondary-font;
    color: $secondary-text-color;
    letter-spacing: 0.3px;
    line-height: 120%;
    font-size: 1.25em;
    padding: 0;
  }

  h3.body-version {
    padding: 36px;
    margin: 0;
    font-family: $primary-font;
    font-weight: 600;
    font-size: 1.75em;
    letter-spacing: 0.3px;
    text-align: center;
    color: $primary-text-color;
  }

  @media (max-width: $breakpoint-tablet) {
    h3.body-version {
      padding: 16px 24px;
      font-size: 1.5em;
    }
  }
</style>
