const scrollToElementById = elementId => {
	const elementById = document.querySelector(`#${elementId}`);
	console.log(elementById);
	if (elementById) {
		window.scrollTo({
			top: elementById.offsetTop,
			left: 0,
			behavior: 'smooth'
		});
	}
};

export default {
	scrollToElementById
};